
 class CryptoUtils {

   static  g1( ) {




     //const { X509Certificate, createPrivateKey } = require('crypto')

     //// Importing fs module
     //const fs = require('fs')

     //// getting object of a PEM encoded X509 Certificate. 
     //const x509 = new X509Certificate
     //  (fs.readFileSync('public-cert.pem'));

     //// getting private key object
     //const key = createPrivateKey
     //  (fs.readFileSync('private-key.pem'))

     //// checking if the public key is consistent or not
     //// by using x509.checkPrivateKey() function
     //const value = x509.checkPrivateKey(key)

     //// display the result
     //if (value)
     //  console.log("public key is consistant")
     //else
     //  console.log("public key is not consitant")







    alert("I am Lucifer");
  }



}


//function greet() {
//  //RSAPublicKey pubkey = (RSAPublicKey) cert.getPublicKey();
//  //field = DatatypeConverter.printHexBinary(pubkey.getEncoded());
//  //System.out.println("Public key : \n" + field);
//}

